<template>
  <b-sidebar
    ref="sidebar"
    shadow
    backdrop
    right
    no-close-on-backdrop
    no-close-on-esc
    no-enforce-focus
    bg-variant="white"
    width="420px"
  >
    <template #header>
      <h3 class="my-1 mx-2 font-weight-bolder">
        <span v-if="!isUpdate">{{ $tc('Global.New') }}&nbsp;</span>
        <span>{{ $tc('Management.Orders.Orders') }}</span>
        <span v-if="isUpdate">&nbsp;{{ $t('Global.Edit') }}</span>
      </h3>
    </template>
    <validation-observer
      ref="rules"
      tag="form"
      class="px-3 mt-2"
      @submit.prevent="submit"
    >
      <section v-if="order">
        <b-form-group
          :label="$t('Management.Orders.OrderNumber') + '*'"
          label-for="orderNumber"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Orders.OrderNumber')"
            rules="required"
          >
            <b-form-input
              id="orderNumber"
              v-model="order.orderNumber"
              :placeholder="
                $t('Global.Write', {
                  subject: $t('Management.Orders.OrderNumber'),
                })
              "
              :state="errors.length > 0 ? false : null"
              :disabled="!can"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group :label="$t('Management.Orders.Name')" label-for="name">
          <b-form-input
            id="name"
            v-model="order.name"
            :placeholder="
              $t('Global.Write', { subject: $t('Management.Orders.Name') })
            "
            :disabled="!can"
            autocomplete="off"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Management.Orders.Customer')"
          label-for="customer"
        >
          <b-form-input
            id="customer"
            v-model="order.customer"
            :placeholder="
              $t('Global.Write', { subject: $t('Management.Orders.Customer') })
            "
            :disabled="!can"
            autocomplete="off"
          />
        </b-form-group>
        <b-form-group
          :label="`${$t('Management.Orders.Need')} (${$t(
            'Management.Orders.PerLayer',
          )})`"
          label-for="orderNumber"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Orders.Need')"
            rules="number"
          >
            <div class="d-flex align-items-center">
              <b-form-input
                id="need"
                v-model="order.need"
                :placeholder="
                  $t('Global.Write', {
                    subject: $t('Management.Orders.Need'),
                  })
                "
                :state="errors.length > 0 ? false : null"
                :disabled="!can || activeState === states.need"
                autocomplete="off"
                @input="checkStates"
              />
              <feather-icon
                icon="LockIcon"
                class="ml-1 hover-grey text-secondary"
                @click="activeState = states.need"
              />
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('Management.Orders.LayersCount')"
          label-for="layersCount"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Orders.LayersCount')"
            rules="number"
          >
            <div class="d-flex align-items-center">
              <b-form-input
                id="layersCount"
                v-model="order.layersCount"
                :placeholder="
                  $t('Global.Write', {
                    subject: $t('Management.Orders.LayersCount'),
                  })
                "
                :state="errors.length > 0 ? false : null"
                :disabled="!can || activeState === states.layersCount"
                autocomplete="off"
                @input="checkStates"
              />
              <feather-icon
                icon="LockIcon"
                class="ml-1 hover-grey text-secondary"
                @click="activeState = states.layersCount"
              />
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('Management.Orders.TotalNeed')"
          label-for="orderNumber"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Orders.TotalNeed')"
            rules="number"
          >
            <div class="d-flex align-items-center">
              <b-form-input
                id="totalNeed"
                v-model="order.totalNeed"
                :placeholder="
                  $t('Global.Write', {
                    subject: $t('Management.Orders.TotalNeed'),
                  })
                "
                :state="errors.length > 0 ? false : null"
                :disabled="!can || activeState === states.totalNeed"
                autocomplete="off"
                @input="checkStates"
              />
              <feather-icon
                icon="LockIcon"
                class="ml-1 hover-grey text-secondary"
                @click="activeState = states.totalNeed"
              />
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </section>
    </validation-observer>
    <template #footer>
      <div class="d-flex justify-content-between m-2">
        <b-button
          v-if="can"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="submit"
        >
          {{ $t('Global.Save') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="sidebarVisible = false"
        >
          {{ $t('Global.Retreat') }}
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { BButton, BSidebar, BFormInput, BFormGroup } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, number } from '@validations'

import { preventUnsavedChangesPopupLevel } from '@/utility/mixins/preventUnsavedChanges'

export default {
  components: {
    BButton,
    BSidebar,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [preventUnsavedChangesPopupLevel],

  props: {
    order: {
      type: Object,
      default: () => null,
    },
    can: {
      type: Boolean,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      default: () => false,
    },
  },

  data: () => ({
    required,
    number,

    activeState: null,
    states: {
      need: 'need',
      layersCount: 'layersCount',
      totalNeed: 'totalNeed',
    },
  }),

  watch: {
    order(order) {
      if (!order) return

      if (
        order.need === null &&
        order.layersCount === null &&
        order.totalNeed === null
      ) {
        return
      }

      this.activeState = this.states.totalNeed
    },
  },

  methods: {
    checkState(toCheck, firstDependency, secondDependency, state, math) {
      if (
        firstDependency !== null &&
        secondDependency !== null &&
        (toCheck === null || this.activeState === state)
      ) {
        if (toCheck === null) {
          this.activeState = state
        }

        if (this.activeState === state) {
          const sum =
            Math.round(math(firstDependency, secondDependency) * 100) / 100
          if (!isNaN(sum)) {
            return sum
          }
          return 0
        }
      }
      return undefined
    },

    checkStates() {
      if (!this.order) return

      const checkNeed = this.checkState(
        this.order.need,
        this.order.totalNeed,
        this.order.layersCount,
        this.states.need,
        (f, s) => parseFloat(f) / parseFloat(s),
      )
      if (checkNeed) this.order.need = checkNeed

      const checkLayersCount = this.checkState(
        this.order.layersCount,
        this.order.totalNeed,
        this.order.need,
        this.states.layersCount,
        (f, s) => parseFloat(f) / parseFloat(s),
      )
      if (checkLayersCount) this.order.layersCount = checkLayersCount

      const checkTotalNeed = this.checkState(
        this.order.totalNeed,
        this.order.need,
        this.order.layersCount,
        this.states.totalNeed,
        (f, s) => parseFloat(f) * parseFloat(s),
      )
      if (checkTotalNeed) this.order.totalNeed = checkTotalNeed
    },

    reset() {
      this.$emit('reset')
      this.$refs.rules.reset()
    },

    async submit() {
      const valid = await this.$refs.rules.validate()

      if (valid) {
        this.$emit('submit')
        return
      }
      this.$alert.invalid()
    },
  },
}
</script>
