const dialog = async (
  { $bvModal, $i18n, $axios, $alert, $createElement },
  { id, subject, individual, endpoint, callback },
  i18nContext = {},
) => {
  try {
    const messageVNode = $createElement('div', {
      class: ['p-1'],
      domProps: {
        innerHTML:
          i18nContext.deleteQuestion ||
          $i18n.t('Global.DeleteQuestion', { individual, subject }),
        style: 'font-size: 16px',
      },
    })

    const choice = await $bvModal.msgBoxConfirm([messageVNode], {
      title: $i18n.t('Global.DeleteSubject', { subject }),
      size: 'sm',
      okVariant: 'primary',
      cancelVariant: 'outline-secondary',
      okTitle: $i18n.t('Global.Delete'),
      cancelTitle: $i18n.t('Global.Retreat'),
      hideHeaderClose: false,
      centered: true,
      'body-class': 'p-2',
    })
    if (!choice) return Promise.resolve(id)
  } catch (error) {
    return Promise.reject(error)
  }

  try {
    await $axios.delete(endpoint + id)
    $alert.delete(individual)

    if (callback) callback()

    return Promise.resolve(id)
  } catch (error) {
    if (error.response.status === 422) {
      let text = $i18n.t('Global.PreventDeleteText')

      if (error.response.data.message.startsWith('Order')) {
        text = $i18n.t('Management.Orders.PreventDeleteText')
      }

      if (error.response.data.message.startsWith('Layer')) {
        text = $i18n.t('Management.Layers.PreventDeleteText')
      }

      await $bvModal.msgBoxOk(text, {
        title: $i18n.t('Management.Layers.PreventDelete'),
        size: 'sm',
        okVariant: 'primary',
        cancelVariant: 'outline-secondary',
        okTitle: $i18n.t('Global.Ok'),
        cancelTitle: $i18n.t('Global.Retreat'),
        hideHeaderClose: false,
        centered: true,
        'body-class': 'p-2',
      })
      return Promise.reject(error)
    }

    $alert.error()
    return Promise.reject(error)
  }
}

export default dialog
