export default async (
  { subject, individuell, active },
  { $createElement, $bvModal, $i18n },
) => {
  const messageVNode = $createElement('div', {
    class: ['p-1'],
    domProps: {
      innerHTML: $i18n.t(`Global.${active ? 'ActiveText' : 'DeactivateText'}`, {
        subject,
        individuell,
      }),
      style: 'font-size: 16px',
    },
  })

  const title = `${subject} ${$i18n
    .t(`Global.To${active ? 'Active' : 'Deactivate'}`)
    .toString()
    .toLowerCase()}`

  return $bvModal.msgBoxConfirm([messageVNode], {
    title,
    size: 'sm',
    okVariant: 'primary',
    cancelVariant: 'outline-secondary',
    okTitle: $i18n.t(`Global.To${active ? 'Active' : 'Deactivate'}`),
    cancelTitle: $i18n.t('Global.Retreat'),
    hideHeaderClose: false,
    centered: true,
    'body-class': 'p-2',
  })
}
