<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="ml-2 d-flex align-items-center"
      variant="primary"
      @click="openSideBar"
    >
      <feather-icon class="mr-1" icon="DatabaseIcon" size="21" />
      {{ $tc('Global.New') }} {{ $tc('Management.Orders.Orders') }}
    </b-button>
    <Form
      ref="form"
      :order="order"
      :can="$can($acl.action.Create, $acl.subjects.Orders)"
      @submit="submit"
      @reset="reset"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Form from './form.vue'

export default {
  components: {
    Form,
    BButton,
  },

  data: () => ({
    order: {
      orderNumber: null,
      active: true,
      name: null,
      customer: null,
      need: null,
      layersCount: null,
      totalNeed: null,
    },
  }),

  methods: {
    reset() {
      this.order = {
        orderNumber: null,
        active: true,
        name: null,
        customer: null,
        need: null,
        layersCount: null,
        totalNeed: null,
      }
    },
    openSideBar() {
      this.$refs.form.sidebarVisible = true
    },
    async submit() {
      try {
        const { data } = await this.$axios.post('orders', this.order)
        this.$alert.create(`${data.orderNumber} ${data.name || ''}`)
        this.$emit('add', data)

        const { form } = this.$refs
        form.dirty = false
        form.sidebarVisible = false
      } catch {
        this.$alert.error()
      }
    },
  },
}
</script>
