var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{ref:"sidebar",attrs:{"shadow":"","backdrop":"","right":"","no-close-on-backdrop":"","no-close-on-esc":"","no-enforce-focus":"","bg-variant":"white","width":"420px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',{staticClass:"my-1 mx-2 font-weight-bolder"},[(!_vm.isUpdate)?_c('span',[_vm._v(_vm._s(_vm.$tc('Global.New'))+" ")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$tc('Management.Orders.Orders')))]),(_vm.isUpdate)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Global.Edit')))]):_vm._e()])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between m-2"},[(_vm.can)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('Global.Save'))+" ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.sidebarVisible = false}}},[_vm._v(" "+_vm._s(_vm.$t('Global.Retreat'))+" ")])],1)]},proxy:true}])},[_c('validation-observer',{ref:"rules",staticClass:"px-3 mt-2",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[(_vm.order)?_c('section',[_c('b-form-group',{attrs:{"label":_vm.$t('Management.Orders.OrderNumber') + '*',"label-for":"orderNumber"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Management.Orders.OrderNumber'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"orderNumber","placeholder":_vm.$t('Global.Write', {
                subject: _vm.$t('Management.Orders.OrderNumber'),
              }),"state":errors.length > 0 ? false : null,"disabled":!_vm.can,"autocomplete":"off"},model:{value:(_vm.order.orderNumber),callback:function ($$v) {_vm.$set(_vm.order, "orderNumber", $$v)},expression:"order.orderNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2763482887)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Management.Orders.Name'),"label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","placeholder":_vm.$t('Global.Write', { subject: _vm.$t('Management.Orders.Name') }),"disabled":!_vm.can,"autocomplete":"off"},model:{value:(_vm.order.name),callback:function ($$v) {_vm.$set(_vm.order, "name", $$v)},expression:"order.name"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Management.Orders.Customer'),"label-for":"customer"}},[_c('b-form-input',{attrs:{"id":"customer","placeholder":_vm.$t('Global.Write', { subject: _vm.$t('Management.Orders.Customer') }),"disabled":!_vm.can,"autocomplete":"off"},model:{value:(_vm.order.customer),callback:function ($$v) {_vm.$set(_vm.order, "customer", $$v)},expression:"order.customer"}})],1),_c('b-form-group',{attrs:{"label":((_vm.$t('Management.Orders.Need')) + " (" + (_vm.$t(
          'Management.Orders.PerLayer'
        )) + ")"),"label-for":"orderNumber"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Management.Orders.Need'),"rules":"number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-input',{attrs:{"id":"need","placeholder":_vm.$t('Global.Write', {
                  subject: _vm.$t('Management.Orders.Need'),
                }),"state":errors.length > 0 ? false : null,"disabled":!_vm.can || _vm.activeState === _vm.states.need,"autocomplete":"off"},on:{"input":_vm.checkStates},model:{value:(_vm.order.need),callback:function ($$v) {_vm.$set(_vm.order, "need", $$v)},expression:"order.need"}}),_c('feather-icon',{staticClass:"ml-1 hover-grey text-secondary",attrs:{"icon":"LockIcon"},on:{"click":function($event){_vm.activeState = _vm.states.need}}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,447260146)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Management.Orders.LayersCount'),"label-for":"layersCount"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Management.Orders.LayersCount'),"rules":"number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-input',{attrs:{"id":"layersCount","placeholder":_vm.$t('Global.Write', {
                  subject: _vm.$t('Management.Orders.LayersCount'),
                }),"state":errors.length > 0 ? false : null,"disabled":!_vm.can || _vm.activeState === _vm.states.layersCount,"autocomplete":"off"},on:{"input":_vm.checkStates},model:{value:(_vm.order.layersCount),callback:function ($$v) {_vm.$set(_vm.order, "layersCount", $$v)},expression:"order.layersCount"}}),_c('feather-icon',{staticClass:"ml-1 hover-grey text-secondary",attrs:{"icon":"LockIcon"},on:{"click":function($event){_vm.activeState = _vm.states.layersCount}}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2354914955)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Management.Orders.TotalNeed'),"label-for":"orderNumber"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Management.Orders.TotalNeed'),"rules":"number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-input',{attrs:{"id":"totalNeed","placeholder":_vm.$t('Global.Write', {
                  subject: _vm.$t('Management.Orders.TotalNeed'),
                }),"state":errors.length > 0 ? false : null,"disabled":!_vm.can || _vm.activeState === _vm.states.totalNeed,"autocomplete":"off"},on:{"input":_vm.checkStates},model:{value:(_vm.order.totalNeed),callback:function ($$v) {_vm.$set(_vm.order, "totalNeed", $$v)},expression:"order.totalNeed"}}),_c('feather-icon',{staticClass:"ml-1 hover-grey text-secondary",attrs:{"icon":"LockIcon"},on:{"click":function($event){_vm.activeState = _vm.states.totalNeed}}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1668762256)})],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }