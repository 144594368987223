<template>
  <b-form-checkbox
    v-model="localValue"
    class="custom-control-success"
    name="check-button"
    switch
    @change="onChangeValue"
  >
    <span class="switch-icon-left">
      <feather-icon icon="UnlockIcon" />
    </span>
    <span class="switch-icon-right">
      <feather-icon icon="LockIcon" />
    </span>
  </b-form-checkbox>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'

import toggleStatusPopup from '@/utility/scripts/toggleStatus'

export default {
  components: {
    BFormCheckbox,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    subject: {
      type: String,
      required: true,
    },
    individuell: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    localValue: false,
  }),

  created() {
    this.localValue = this.value
  },

  methods: {
    async onChangeValue(newValue) {
      try {
        const wannaChange = await toggleStatusPopup(
          {
            subject: this.subject,
            individuell: this.individuell,
            active: newValue,
          },
          this,
        )

        if (wannaChange === true) {
          this.$emit('change-status', newValue)
          this.localValue = newValue
          return
        }
        this.localValue = !newValue
      } catch (error) {
        this.localValue = !newValue
      }
    },
  },
}
</script>
