<template>
  <span>
    <feather-icon
      v-b-tooltip.hover.top="
        $t(
          `Global.${
            $can($acl.action.Update, $acl.subjects.Orders)
              ? 'EditSubject'
              : 'ViewSubject'
          }`,
          { subject: $tc('Management.Orders.Orders') },
        )
      "
      :icon="
        $can($acl.action.Update, $acl.subjects.Orders) ? 'Edit2Icon' : 'EyeIcon'
      "
      size="16"
      class="hover-green"
      @click="openSideBar"
    />
    <Form
      ref="form"
      :order="updatedData"
      :submit-btn-text="`${$t('Global.Save')} ${$tc(
        'Management.Orders.Orders',
      )}`"
      :can="$can($acl.action.Update, $acl.subjects.Orders)"
      :is-update="true"
      @submit="submit"
    />
  </span>
</template>

<script>
import Form from './form.vue'

export default {
  components: {
    Form,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    updatedData: null,
  }),

  methods: {
    openSideBar() {
      this.updatedData = { ...this.order }
      this.$refs.form.sidebarVisible = true
    },
    async submit() {
      try {
        const { data } = await this.$axios.patch(
          `orders/${this.order.id}`,
          this.updatedData,
        )

        this.$alert.update(`${data.orderNumber} ${data.name || ''}`)

        Object.keys(this.updatedData).forEach(key => {
          this.order[key] = this.updatedData[key]
        })

        const { form } = this.$refs
        form.dirty = false
        form.sidebarVisible = false
      } catch {
        this.$alert.error()
      }
    },
  },
}
</script>
